.errorMsg,
.loadingMsg {
  width: 25%;
  margin: auto;
  margin-left: 38%;
  margin-right: 38%;
}

@media only screen and (max-width: 900px) {
  .errorMsg,
  .loadingMsg {
    width: 40%;
    margin: auto;
    margin-left: 30%;
    margin-right: 30%;
  }
}

@media only screen and (max-width: 768px) {
  .errorMsg,
  .loadingMsg {
    width: 50%;
    margin: auto;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media only screen and (max-width: 576px) {
  .errorMsg,
  .loadingMsg {
    width: 80%;
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 400px) {
  .errorMsg,
  .loadingMsg {
    width: 90%;
    margin: auto;
    margin-left: 5%;
    margin-right: 5%;
  }
}
